import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiEvent, apiEvents } from '../../services/api'
import {
  User,
  LoginProps,
  ProfileFormProps,
  ProfilePasswordProps,
  TokenProps,
  ResetPaswordProps,
} from './interfaces'

import dashboardMessage from '../../components/Message'
import { DashboardMessageTypes } from '../../utils/enums'
import { errorsToString } from '../../utils/functions'

export const asyncFetchRegister = createAsyncThunk(
  'user/asyncFetchRegister',
  async (
    { additionalHeaders, body }: { additionalHeaders: any; body: ProfileFormProps },
    thunkAPI,
  ) => {
    const response = await apiEvents(additionalHeaders)
      .post<User | null>('auth/register/', body)
      .catch(function(error) {
        if (error.response) {
          dashboardMessage(DashboardMessageTypes.error, errorsToString(error.response.data))
        }
      })

    if (response) {
      return response.data
    }
  },
)

export const asyncFetchLogin = createAsyncThunk(
  'user/asyncFetchLogin',
  async ({ additionalHeaders, body }: { additionalHeaders: any; body: LoginProps }, thunkAPI) => {
    const response = await apiEvents(additionalHeaders)
      .post<User | null>('auth/login/', body)
      .catch(function(error) {
        if (error.response) {
          dashboardMessage(DashboardMessageTypes.error, errorsToString(error.response.data))
        }
      })

    if (response) {
      return response.data
    }
  },
)

// export const asyncFetchAccountUpdate = createAsyncThunk(
//   'dashboard/asyncFetchAccount',
//   async (
//     {
//       additionalHeaders,
//       body,
//       token,
//     }: {
//       additionalHeaders: any
//       body: ProfileFormProps | ProfilePasswordProps
//       token: string | null
//     },
//     thunkAPI,
//   ) => {
//     const response = await apiEvents(additionalHeaders).put<User | null | string>('account/', body)
//     if (response) {
//       thunkAPI.dispatch(
//         asyncFetchTokenRefresh({ additionalHeaders: {}, body: { token: token || null } }),
//       )
//     }
//     return response.data
//   },
// )

export const asyncFetchAccountUpdate = createAsyncThunk(
  'user/asyncFetchAccountUpdate',
  async (
    {
      additionalHeaders,
      body,
      token,
    }: {
      additionalHeaders: any
      body: ProfileFormProps | ProfilePasswordProps
      token: string | null
    },
    thunkAPI,
  ) => {
    const response = await apiEvents(additionalHeaders)
      .put<any>('account/', body)
      .catch(function(error) {
        if (error.response) {
          dashboardMessage(
            DashboardMessageTypes.warning,
            'Coś poszło nie tak z aktualizowaniem profilu.',
          )
        }
      })

    if (response) {
      if (response) {
        thunkAPI.dispatch(
          asyncFetchTokenRefresh({ additionalHeaders: {}, body: { token: token || null } }),
        )
      }
      return response.data
    }
  },
)

// export const asyncFetchAccountDelete = createAsyncThunk(
//   'dashboard/asyncFetchAccount',
//   async ({ additionalHeaders }: { additionalHeaders: any }, thunkAPI) => {
//     const response = await apiEvents(additionalHeaders).delete<User | null | string>('account/')
//     if (response) {
//       return response.data
//     }
//   },
// )

export const asyncFetchAccountDelete = createAsyncThunk(
  'user/asyncFetchAccountDelete',
  async ({ additionalHeaders }: { additionalHeaders: any }, thunkAPI) => {
    const response = await apiEvents(additionalHeaders)
      .delete<any>('account/')
      .catch(function(error) {
        if (error.response) {
          dashboardMessage(DashboardMessageTypes.error, 'Coś poszło nie tak z usunięciem konta.')
        }
      })

    if (response) {
      return response.data
    }
  },
)

export const asyncFetchTokenRefresh = createAsyncThunk(
  'user/asyncFetchTokenRefresh',
  async ({ additionalHeaders, body }: { additionalHeaders: any; body: TokenProps }, thunkAPI) => {
    const response = await apiEvents(additionalHeaders)
      .post<User | null>('auth/token/refresh/', body)
      .catch(function(error) {
        if (error.response) {
          dashboardMessage(
            DashboardMessageTypes.warning,
            'Zaloguj się ponownie podając prawidłowe dane.',
          )
        }
      })

    if (response) {
      return response.data
    }
  },
)

export const asyncRegisterEvent = createAsyncThunk(
  'user/asyncRegisterEvent',
  async (
    {
      slug,
      additionalHeaders,
      body,
    }: {
      slug: string
      additionalHeaders: any
      body?: any
    },
    thunkAPI,
  ) => {
    const response = await apiEvent(slug, additionalHeaders)
      .put('account/event/szarykot/register/', body)
      .catch(function(error) {
        if (error.response) {
          dashboardMessage(DashboardMessageTypes.error, errorsToString(error.response.data))
        }
      })
    if (response) {
      dashboardMessage(DashboardMessageTypes.success, 'Zarejestrowano na wydarzenie.')
      thunkAPI.dispatch(asyncFetchUserDetails({ additionalHeaders: additionalHeaders }))
    }
  },
)

export const asyncFetchPasswordReset = createAsyncThunk(
  'user/asyncFetchPasswordReset',
  async (
    {
      additionalHeaders,
      body,
    }: {
      additionalHeaders: any
      body: {
        email: string
      }
    },
    thunkAPI,
  ) => {
    const response = await apiEvents(additionalHeaders)
      .post<User | null | string>('auth/password/reset/', body)
      .catch(function(error) {
        if (error.response) {
          dashboardMessage(DashboardMessageTypes.error, errorsToString(error.response.data))
        }
      })
    if (response) {
      dashboardMessage(DashboardMessageTypes.success, 'Wysłano mail z resetem hasła.')
      return response.data
    }
  },
)

export const asyncFetchPasswordResetConfirm = createAsyncThunk(
  'user/asyncFetchPasswordResetConfirm',
  async ({
    additionalHeaders,
    body,
    isSuccess,
  }: {
    additionalHeaders: any
    body: {
      password: string
      token: string
    }
    isSuccess: () => void
  }) => {
    const response = await apiEvents(additionalHeaders)
      .post<ResetPaswordProps>('auth/password/reset/confirm/', body)
      .catch(function(error) {
        if (error.response) {
          dashboardMessage(DashboardMessageTypes.error, errorsToString(error.response.data))
        }
      })
    if (response) {
      dashboardMessage(
        DashboardMessageTypes.success,
        'Nowe hasło ustawione poprawnie. Możesz się zalogować!',
      )
      isSuccess()
      return response.data
    }
  },
)

export const asyncFetchPasswordChange = createAsyncThunk(
  'user/asyncFetchPasswordChange',
  async (
    {
      additionalHeaders,
      body,
    }: {
      additionalHeaders: any
      body: ProfileFormProps | ProfilePasswordProps
    },
    thunkAPI,
  ) => {
    const response = await apiEvents(additionalHeaders)
      .put<User | null | string>('account/password/change/', body)
      .catch(function(error) {
        if (error.response) {
          dashboardMessage(DashboardMessageTypes.error, errorsToString(error.response.data))
        }
      })
    if (response) {
      dashboardMessage(DashboardMessageTypes.success, 'Zaktualizowano hasło.')
      return response.data
    }
  },
)

export const asyncFetchUserDetails = createAsyncThunk(
  'user/asyncFetchUserDetails',
  async (
    {
      additionalHeaders,
    }: {
      additionalHeaders: any
    },
    thunkAPI,
  ) => {
    const response = await apiEvents(additionalHeaders)
      .get<User | null>('account/')
      .catch(function(error) {
        if (error.response) {
          dashboardMessage(DashboardMessageTypes.error, errorsToString(error.response.data))
        }
      })
    if (response) {
      dashboardMessage(DashboardMessageTypes.success, 'Zaktualizowano dane.')
      return response.data
    }
  },
)
